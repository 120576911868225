/* tailwind.css */

/* Add any existing imports */

/* Your existing Tailwind CSS imports */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Custom styles for screens over 1400px */
@media screen and (min-width: 1400px) {
    /* Increase font size and padding for larger screens */
    .text-text-blue {
        font-size: 3rem; /* 25% increase from 5rem */
    }

    .outline-secondary,
    .bg-accent,
    .bg-primary {
        padding: 1rem 2.5rem; /* 25% increase in padding */
        font-size: 1.25rem; /* 25% increase in font size */
    }

    .grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
